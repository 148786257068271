import { getRandomInt } from "@/libs";
import * as lottie from "@/libs/lottie";

import * as anim19 from "./Cigarette_1-9.json";
import * as anim29 from "./Cigarette_2-9.json";
import * as anim39 from "./Cigarette_3-9.json";
import * as anim49 from "./Cigarette_4-9.json";
import * as anim59 from "./Cigarette_5-9.json";
import * as anim69 from "./Cigarette_6-9.json";
import * as anim79 from "./Cigarette_7-9.json";
import * as anim89 from "./Cigarette_8-9.json";
import * as anim99 from "./Cigarette_9-9.json";

import * as anim18 from "./Cigarette_1-8.json";
import * as anim28 from "./Cigarette_2-8.json";
import * as anim38 from "./Cigarette_3-8.json";
import * as anim48 from "./Cigarette_4-8.json";
import * as anim58 from "./Cigarette_5-8.json";
import * as anim68 from "./Cigarette_6-8.json";
import * as anim78 from "./Cigarette_7-8.json";
import * as anim88 from "./Cigarette_8-8.json";
import * as anim98 from "./Cigarette_9-8.json";

const animations = [
  [
    null,
    anim19,
    anim29,
    anim39,
    anim49,
    anim59,
    anim69,
    anim79,
    anim89,
    anim99,
  ],
  [
    null,
    anim18,
    anim28,
    anim38,
    anim48,
    anim58,
    anim68,
    anim78,
    anim88,
    anim98,
  ],
];

export function loadAnimation(shakeTime, label) {
  const anim = shakeTime === 0 ? getRandomInt(1, 9) : label;
  const animationData = animations[shakeTime][anim];

  const params = {
    loop: false,
    autoplay: false,
    renderer: "svg",
    animationData: animationData.default,
    container: document.getElementById("lottie"),
  };

  return lottie.loadAnimation(params);
}
