import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const vuexLocal = new VuexPersistence({ storage: window.localStorage });

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      firstname: "",
      lastname: "",
    },
    shake: 0,
    accessToken: "",
    game_data: null,
    active_instance: 0,
    instance_data: null,
    updated_game_data: null,
  },
  getters: {
    userInfo(state) {
      return state.user;
    },
    accessToken(state) {
      return state.accessToken;
    },
    shakeTime(state) {
      return state.shake;
    },
    gameData(state) {
      return state.game_data;
    },
    instanceData(state) {
      return state.instance_data;
    },
    isInstanceActive(state) {
      return state.active_instance === 1;
    },
    isReadyToPlay(state, getters) {
      return getters.isInstanceActive && state.game_data.chances_left > 0;
    },
    updatedGameData(state) {
      return state.updated_game_data;
    },
  },
  actions: {
    initialize({ dispatch }, data) {
      dispatch("setGameData", data);
      dispatch("setUserProfile", data);
      dispatch("setAccessToken", data.token);
    },
    setAccessToken({ commit }, data) {
      commit("SET_TOKEN", data);
      localStorage.setItem("accessToken", data);
    },
    setUserProfile({ commit }, data) {
      commit("SET_USER_INFO", {
        id: data.user,
        firstname: data.firstname,
        lastname: data.lastname,
      });
    },
    adjustShakeTime({ commit }, data) {
      commit("ADJUST_SHAKE_TIME", data);
    },
    setUpdatedGameData({ commit }, data) {
      commit("SET_UPDATED_GAME_DATA", data);
    },
    setGameData({ commit }, data) {
      commit("SET_GAME_INFO", {
        game_data: {
          chances_left: data.chances_left,
          label1: data.label1,
          label2: data.label2,
          point1: data.point1,
          point2: data.point2,
          label1_meaning1: data.label1_meaning1,
          label1_meaning2: data.label1_meaning2,
          label2_meaning1: data.label2_meaning1,
          label2_meaning2: data.label2_meaning2,
        },
        instance_data: data.instance_data,
        active_instance: data.active_instance,
      });
      commit("SET_UPDATED_GAME_DATA", null);
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_USER_INFO(state, payload) {
      state.user = payload;
    },
    SET_GAME_INFO(state, payload) {
      state.game_data = payload.game_data;
      state.instance_data = payload.instance_data;
      state.active_instance = payload.active_instance;
    },
    ADJUST_SHAKE_TIME(state, payload) {
      state.shake = payload;
    },
    SET_UPDATED_GAME_DATA(state, payload) {
      state.updated_game_data = payload;
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],

  // https://github.com/championswimmer/vuex-persist#support-strict-mode
  strict: debug,
});
