<template>
  <div class="button">
    <div class="wrapper">
      <img v-if="isShaking" class="disabled" src="@/assets/03_Button-2.png" />
      <img
        v-else-if="!isShaking && !showShakeAgain"
        class="ready"
        :class="{
          shake: shake,
        }"
        src="@/assets/03_Button-3.png"
      />
      <img
        v-else-if="!isShaking && showShakeAgain && shakeTime == 1"
        class="ready shake-again"
        :class="{
          shake: shake,
        }"
        src="@/assets/03_Button-5.png"
      />
      <img
        v-else-if="!isShaking && showShakeAgain && shakeTime == 2"
        class="go-back"
        src="@/assets/03_Button-7.png"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShakeButton",
  props: ["isShaking", "state"],

  data() {
    return {
      shake: false,
    };
  },

  computed: {
    ...mapGetters(["shakeTime"]),

    showShakeAgain() {
      return this.state == "RESULT";
    },
  },

  created() {
    setInterval(() => (this.shake = !this.shake), 1500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/shake.button.scss";
</style>
