<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "node_modules/swiper/swiper.scss";
@import "@/styles/app.scss";
</style>
