<template>
  <div class="logo">
    <img src="@/assets/01_LOGO.png" alt="mini vivi logo" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo {
  top: 6vh;
  display: flex;
  position: relative;

  > img {
    width: auto;
    height: auto;
    max-width: 100vw;
    max-height: 25vh;
    margin: 0 auto;

    animation: zoomIn;
    animation-duration: 2s;
  }
}

// iPhone 6/7/8
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .logo {
    top: 5vh;
    > img {
      max-height: 25vh;
    }
  }
}
</style>
