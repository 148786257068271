<template>
  <div id="landing">
    <div class="wrapper centered">
      <img class="logo" src="@/assets/01_LOGO.png" />
      <div class="action" v-if="ready">
        <button class="btn" @click="$router.push({ name: 'Home' })">
          เล่นเกม
        </button>
        <button
          class="btn leaderboard-link"
          v-if="gameLeaderboard"
          @click="openLeaderboard()"
        >
          ดูผลการจัดอันดับ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { login } from "@/api/http";

export default {
  name: "Landing",

  data() {
    return {
      ready: false,
      gameLeaderboard: false,
    };
  },

  async created() {
    this.init();
  },

  methods: {
    ...mapActions({
      initialize: `initialize`,
    }),

    async init() {
      try {
        let QUERY = "";

        switch (process.env.NODE_ENV) {
          case "development":
            QUERY = process.env.VUE_APP_SALEFORCE_QUERY;
            break;

          default:
            QUERY = this.$route.query.QUERY || this.$route.query.query;
            break;
        }

        localStorage.setItem("SALEFORCE_QUERY", QUERY);

        const response = await login(QUERY);
        this.initialize(response);

        this.ready = true;

        if (+response.game_leaderboard === 1) {
          this.gameLeaderboard = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    openLeaderboard() {
      const queryKey = this.$route.query.QUERY ? "QUERY" : "query";
      const sf_query_token = this.$route.query.QUERY || this.$route.query.query;

      if (window.location.host === `fortune.gameworld.vivi-th.com`) {
        window.parent.location.href =
          `https://leaderboard.vivi-th.com?${queryKey}=` +
          encodeURIComponent(sf_query_token);
      } else {
        window.parent.location.href =
          `https://leaderboard-sandbox.vivi-th.com?${queryKey}=` +
          encodeURIComponent(sf_query_token);
      }
    },
  },
};
</script>

<style scoped>
#landing {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.wrapper {
  width: 100%;
  margin: 2rem auto 0;
  display: block;
  position: fixed;
  text-align: center;
  background-color: #fff;
}

.logo {
  max-width: 60%;
  margin-bottom: 4em;
}

.btn {
  color: #0c2d5d;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 4rem;
  padding: 0.5rem 3rem;
  margin: 6rem auto 2rem;
  border: 0.5rem solid #0c2d5d;
  box-shadow: 3px 6px 12px #929292;

  background: rgb(214, 149, 33);
  background: linear-gradient(
    90deg,
    rgb(245 198 15) 0%,
    rgb(253 199 4) 35%,
    rgb(218 121 26) 100%
  );
}

.action {
  display: flex;
  flex-direction: column;
}

.action button {
  margin: 0 auto;
  min-width: 300px;
  margin-bottom: 1em;
}

.centered {
  position: fixed;
  top: 35%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
