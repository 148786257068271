<template>
  <div refs="lottie" id="lottie"></div>
</template>

<script>
import { mapGetters } from "vuex";
import { getRandomInt } from "@/libs";
import { loadAnimation } from "@/assets/cigarettes";

export default {
  props: ["label"],
  data() {
    return {
      animation: null,
    };
  },
  computed: {
    ...mapGetters(["shakeTime"]),
  },
  mounted() {
    setTimeout(this.init, 50);
  },
  methods: {
    init() {
      this.animation = loadAnimation(this.shakeTime, this.label);
      this.animation.onComplete = this.complete;
    },
    play() {
      const stopFrame = getRandomInt(200, 250);

      this.animation
        ? this.animation.playSegments([0, stopFrame], true)
        : setTimeout(() => {
            this.animation.playSegments([0, stopFrame], true);
          }, 100);
    },
    playLastScene() {
      this.animation.onComplete = null;
      this.animation.playSegments([100, 200], true);
    },
    pause() {
      this.animation.pause();
    },
    stop() {
      this.animation.stop();
    },
    complete() {
      this.playLastScene();
      this.$emit("complete");
    },
  },
};
</script>

<style lang="scss" scoped>
#lottie {
  top: -10%;
  left: -15%;
  width: 130%;
  height: 130%;

  position: relative;
}
</style>
