<template>
  <div class="score-bar">
    <div class="wrapper">
      <div v-if="score.first == 0" class="board left" :class="{ pulse: pulse }">
        <p>เขย่าครั้งที่ 1</p>
      </div>
      <div v-else-if="score.first !== 0" class="board left active">
        <p>{{ score.first }} คะแนน</p>
      </div>

      <div
        v-if="score.second == 0"
        class="board right"
        :class="{ pulse: pulse && score.first > 0 }"
      >
        <p>เขย่าครั้งที่ 2</p>
      </div>
      <div v-else-if="score.second !== 0" class="board right active">
        <p>{{ score.second }} คะแนน</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ScoreBar",
  props: ["score"],
  data() {
    return { pulse: true };
  },
  watch: {
    shakeTime(v) {
      this.pulse = +v === 1;
      this.delayStopPulse();
    },
  },
  computed: {
    ...mapGetters(["shakeTime"]),
  },
  methods: {
    delayStopPulse() {
      setTimeout(() => {
        this.pulse = false;
      }, 6 * 1000);
    },
  },
  created() {
    this.delayStopPulse();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/score.bar.scss";
</style>
