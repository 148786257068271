<template>
  <div class="result">
    <ConfettiPaper />
    <div class="cigarette">
      <p class="number">{{ result.label }}</p>
      <img src="@/assets/05_Cigarette.png" />
    </div>
    <div class="label">
      <div class="wrapper">
        <img src="@/assets/06_Red-Paper.png" />
        <h2>เลข {{ result.label }}</h2>
        <p>{{ result.label_meaning1 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ConfettiPaper from "@/components/ConfettiPaper.vue";

export default {
  name: "Result",
  props: ["result"],
  components: { ConfettiPaper },
};
</script>

<style lang="scss" scoped>
@import "@/styles/result.scss";
</style>
