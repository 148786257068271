<template>
  <div id="howto">
    <button class="close" @click="close">
      <img src="@/assets/close.png" />
    </button>
    <div class="wrapper">
      <div class="title">
        <h2>วิธีเล่น</h2>
        <h3>“VIP เขย่าเสี่ยงโชค”</h3>
        <p>
          พิชิตภารกิจได้ง่ายๆ<br />
          เขย่าซองบุหรี่เพื่อลุ้นรับคะแนน
        </p>
      </div>
      <swiper :options="swiperOption">
        <swiper-slide v-for="(s, i) in screen" :key="i">
          <img :src="s.img" />
          <p v-html="s.text"></p>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Howto",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      screen: [
        {
          text: "1. กดที่ปุ่ม “เริ่มเกม”",
          img: require("@/assets/08_Howto-1.jpg"),
        },
        {
          text: "2. กดที่ปุ่ม “กดเพื่อเขย่า”",
          img: require("@/assets/08_Howto-2.jpg"),
        },
        {
          text: "3. รับคะแนนครั้งแรก<br />และกด “เขย่าอีกครั้ง”",
          img: require("@/assets/08_Howto-3.jpg"),
        },
        {
          text: "4. รับคะแนนครั้งที่สอง<br />และกด “ตกลง”",
          img: require("@/assets/08_Howto-4.jpg"),
        },
        {
          text: "5. ดูสรุปคะแนนที่ได้",
          img: require("@/assets/08_Howto-5.jpg"),
        },
      ],
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/howto.scss";
</style>

<style lang="scss">
@import "@/styles/vendors/swiper.scss";
</style>
