<template>
  <div id="start" :class="{ 'show-result': isResultShowing }">
    <ScoreBar :score="score" />

    <transition
      enter-active-class="animate__animated animate__fadeInUpBig"
      leave-active-class="animate__animated animate__fadeOut animate__slower"
    >
      <Anim
        ref="anim"
        :label="label"
        @complete="onComplete"
        v-if="isReadyOrPlaying"
      />
    </transition>

    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slower"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <Result v-if="isResultShowing" :result="currentResult" />
    </transition>

    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeInUpBig animate__slower"
      leave-active-class="animate__animated animate__fadeOutDownBig animate__delay-1s"
    >
      <BottomBar v-if="isReadyOrPlaying" />
    </transition>

    <ShakeButton :state="state" :isShaking="shaking" @click.native="play" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Anim from "@/components/Anim.vue";
import Result from "@/components/Result.vue";
import ScoreBar from "@/components/ScoreBar.vue";
import BottomBar from "@/components/BottomBar.vue";
import ShakeButton from "@/components/ShakeButton.vue";

import * as utils from "@/libs";
import { finish } from "@/api/http";

export default {
  name: "Start",
  data() {
    return {
      shaking: false,

      // READY, PLAY, RESULT
      state: "READY",

      label: 0,

      score: {
        first: 0,
        second: 0,
      },
    };
  },
  components: {
    Anim,
    Result,
    ScoreBar,
    BottomBar,
    ShakeButton,
  },
  computed: {
    ...mapGetters(["gameData", "shakeTime"]),

    currentResult() {
      const {
        // 1
        label1,
        point1,
        label1_meaning1,
        label1_meaning2,
        // 2
        label2,
        point2,
        label2_meaning1,
        label2_meaning2,
      } = this.gameData;

      return this.shakeTime === 1
        ? {
            label: label1,
            point: point1,
            label_meaning1: label1_meaning1,
            label_meaning2: label1_meaning2,
          }
        : {
            label: label2,
            point: point2,
            label_meaning1: label2_meaning1,
            label_meaning2: label2_meaning2,
          };
    },
    isResultShowing() {
      return this.state == "RESULT";
    },
    isReadyOrPlaying() {
      return this.state == "READY" || this.state == "PLAY";
    },
    isFirstTimePlaying() {
      return this.shakeTime === 1;
    },
    isSecondTimePlaying() {
      return this.shakeTime === 2;
    },
  },
  methods: {
    ...utils,

    ...mapActions(["setUpdatedGameData", "adjustShakeTime"]),

    play() {
      if (this.shaking) {
        return;
      }

      if (this.isSecondTimePlaying) {
        this.renderSummaryPage();
        return;
      }

      this.state = "PLAY";
      setTimeout(this.shake, this.isFirstTimePlaying ? 0 : 1000);
    },
    shake() {
      this.$refs.anim.play();
      this.shaking = true;
    },
    async onComplete() {
      this.shaking = false;
      this.state = "RESULT";
      this.label = this.gameData.label1;

      const round = this.shakeTime + 1;
      this.adjustShakeTime(round);

      this.renderScore();

      if (this.isSecondTimePlaying) {
        const response = await finish();
        this.setUpdatedGameData(response);
      }
    },

    renderSummaryPage() {
      this.adjustShakeTime(0);

      this.$router.replace({
        name: "Home",
        query: { state: "complete" },
      });
    },

    renderScore() {
      const { point1, point2, label1, label2 } = this.gameData;

      switch (this.shakeTime) {
        case 1:
          this.label = label1;
          this.score.first = point1;
          break;
        case 2:
          this.label = label2;
          this.score.second = point2;
          break;
      }
    },

    onUnload(event) {
      this.adjustShakeTime(0);
    },
  },

  mounted() {
    this.renderScore();
  },

  created() {
    document.addEventListener("beforeunload", this.onUnload);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/start.scss";
</style>
