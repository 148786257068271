<template>
  <div class="cigarette">
    <img src="@/assets/03_Cigarette.png" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/cigarette.scss";
</style>
