import { render, staticRenderFns } from "./PlayButton.vue?vue&type=template&id=0bd1675c&scoped=true"
import script from "./PlayButton.vue?vue&type=script&lang=js"
export * from "./PlayButton.vue?vue&type=script&lang=js"
import style0 from "./PlayButton.vue?vue&type=style&index=0&id=0bd1675c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd1675c",
  null
  
)

export default component.exports