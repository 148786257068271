<template>
  <div class="button">
    <div class="wrapper">
      <img
        v-if="ready"
        src="@/assets/03_Button.png"
        :class="{ shake: shake }"
      />
      <img v-else src="@/assets/03_Button-4.png" class="disabled" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayButton",

  props: ["ready"],

  data() {
    return {
      shake: false,
    };
  },

  created() {
    setInterval(() => (this.shake = !this.shake), 1500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/play.button.scss";
</style>
