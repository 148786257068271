<template>
  <div class="summary">
    <div class="wrapper">
      <img src="@/assets/01_LOGO.png" class="logo" />
      <p class="title">ขอแสดงความยินดี!</p>
      <h2>{{ fullname }}</h2>
      <p>วันนี้คุณได้</p>
      <div class="score">
        <span>{{ totalPoints }} คะแนน</span>
      </div>
      <p>อย่าลืมกดเข้ามา<br />เขย่าเสี่ยงโชคได้ใหม่ในครั้งถัดไป</p>
    </div>
    <div class="actions">
      <img class="close-btn" @click="onClose" src="@/assets/03_Button-6.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Summary",

  computed: {
    ...mapGetters(["userInfo", "gameData", "instanceData", "updatedGameData"]),

    totalPoints() {
      const { point1, point2 } = this.gameData;
      return point1 + point2;
    },
    fullname() {
      const { firstname, lastname } = this.userInfo;
      return `คุณ ${firstname} ${lastname}`;
    },
  },

  methods: {
    ...mapActions(["setGameData", "adjustShakeTime"]),

    onClose() {
      this.instanceData.redirect_url
        ? this.redirectToSpecifiedPage()
        : this.gotoHomePage();
    },

    redirectToSpecifiedPage() {
      window.parent.location = this.instanceData.redirect_url;
    },

    gotoHomePage() {
      const QUERY = localStorage.getItem("SALEFORCE_QUERY");
      window.location = `./?query=${QUERY}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/summary.scss";
</style>
