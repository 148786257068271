<template>
  <div class="bottom-bar">
    <div class="overlay"></div>
    <img src="@/assets/02_Filter-1.png" class="ribbon" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/bottom.bar.scss";
</style>
