<template>
  <div id="home">
    <Howto v-if="showHowto" @close="hideHowto" />
    <Logo />
    <Cigarette />
    <BottomBar />
    <PlayButton :ready="isReadyToPlay" @click.native="start" />

    <Summary v-if="showSummary" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Logo from "@/components/Logo.vue";
import Howto from "@/components/Howto.vue";
import Summary from "@/components/Summary.vue";
import Cigarette from "@/components/Cigarette.vue";
import BottomBar from "@/components/BottomBar.vue";
import PlayButton from "@/components/PlayButton.vue";

export default {
  name: "Home",

  components: {
    Logo,
    Howto,
    Summary,
    Cigarette,
    BottomBar,
    PlayButton,
  },

  data() {
    return { showHowto: false };
  },

  computed: {
    ...mapGetters(["shakeTime", "isReadyToPlay", "gameData"]),

    showSummary() {
      return this.$route.query.state == "complete";
    },
  },

  methods: {
    ...mapActions(["adjustShakeTime"]),

    start() {
      if (this.isReadyToPlay) {
        this.$router.push({ name: "Start" }).catch((err) => {});
      }
    },

    hideHowto() {
      this.showHowto = false;
    },

    showHowtoPopup() {
      this.showHowto = true;
    },
  },

  created() {
    if (this.shakeTime === 2) this.adjustShakeTime(0);

    if (this.$route.query.state != "complete")
      setTimeout(this.showHowtoPopup, 2500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/home.scss";
</style>
